
import React from 'react'
import '../../../src/DetailPage.css'
import '../../../src/PreviewPage.scss'
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import Footer from '../../components/Footer';
import Header from '../../components/Header';

const iconImage = require("./assets/redlightcam-icon.JPEG");
const screenShot1 = require("./assets/redlightcam-1.jpg");
const screenShot2 = require("./assets/redlightcam-2.jpg");
const screenShot3 = require("./assets/redlightcam-3.jpg");

export function useTitle(title) {

  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title

    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
      document.getElementById("gamesMenuText").style.borderBottom = "solid 2px";
      document.getElementById("gamesMenuText").style.borderColor = "red";

    }

  })
}

const RedLightCamDetailPage = () => {
  useTitle("redlightcam - RISE")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
      <Helmet>
        <title>redlightcam - THE RISE COLLECTION</title>
        <meta
          name="description"
          content="Through redlightcam we capture the local car scene like you are on the ground with us. With capturing build of all kinds + meeting business owners within the space our mission is to highlight talent that might've been missed otherwise."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header/>
      <div className="app-container">

        <div className="game-info">
          <img src={iconImage} alt="Game Icon" className="game-icon" />
          <div className="game-details">
            <h2 style={{ fontSize: "18px" }}>REDLIGHTCAM</h2>
            <p ><a style={{ fontSize: '15px', color: 'red', fontWeight: 'bold' }} href="/#about-us">THE RISE COLLECTION</a></p>
            <div className='tag-container'>
              <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', width: '65px', paddingTop: '0.98px' }}>BRAND</div>
            </div>
          </div>
        </div>
        <br></br>
        <div style={{ marginBottom: '20px' }}>
          <h2>About the Brand</h2>
          <p style={{ fontSize: '18px' }}>
            At redlightcam, we provide an immersive perspective of the local car scene, allowing you to experience it as if you were "on the ground" with us. Our mission is to document a diverse range of automotive builds and engage with business owners in the industry, showcasing talent that may otherwise go unnoticed.
          </p>
          <br></br>
        </div>
        <h2>Examples</h2>
        <div className="screenshot-container-web">
          <img src={screenShot1} alt='REDLIGHTCAM_SCREENSHOT_1' />
          <img src={screenShot2} alt='REDLIGHTCAM_SCREENSHOT_2' />
          <img src={screenShot3} alt='REDLIGHTCAM_SCREENSHOT_3' />
        </div>
        <br></br>
        <div style={{ fontSize: '18px' }}>
          <h2 style={{ fontSize: '24px' }}>Additional Information</h2>
          <span>          You can reach us with "brand specific" questions using any <a href="/#contact-us" target="_blank" style={{color: 'red', fontWeight: 'bold'}}>CONTACT</a> option found on our site! The format of "brands" in our portfolio is subject to change.. for now enjoy the content generated by the redlightcam team on the social media platforms linked below.
          </span>
          <br></br>
          <h2 style={{ fontSize: '24px' }}>Explore</h2>
          <p style={{ fontSize: '18px' }}>
            Find content associated to this project linked below:
          </p>
          <ul style={{ fontSize: '18px', paddingBottom: '25px' }}>
            <li><a href="https://www.instagram.com/redlightcam/?hl=en"
              style={{ color: "red", fontWeight: 'bold'}}>INSTAGRAM</a></li>
            <li><a href="https://www.youtube.com/channel/UCAxztMJo0zzVGyepmDsuGnw?app=desktop"
              style={{ color: "red", fontWeight: 'bold'}}>YOUTUBE</a></li>
            <li><a href="https://www.facebook.com/p/redlightcam-100086354563813/"
              style={{ color: "red", fontWeight: 'bold'}}>FACEBOOK</a></li>


          </ul>
        </div>
      </div>
      <div style={{ backgroundColor: 'black', }}>
        <div className="footer-content" style={{ backgroundColor: 'black' }}>
          <Footer/>
        </div>
      </div>

    </div>
  )
}

export default RedLightCamDetailPage
