import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faDiscord
} from '@fortawesome/free-brands-svg-icons';
import './SocialMediaIcons.css'; // You'll need to create this CSS file
const SocialMediaIcons = () => {
  const socialLinks = [
    { icon: faYoutube, url: 'https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q', name: 'YouTube' },
    { icon: faFacebook, url: 'https://www.facebook.com/rwtrcsc', name: 'Facebook' },
    { icon: faInstagram, url: 'https://www.instagram.com/rwtrcsc/?hl=en', name: 'Instagram' },
    { icon: faTwitter, url: 'https://x.com/rwtrcsc', name: 'Twitter' },
    { icon: faLinkedin, url: 'https://www.linkedin.com/company/therisecollection', name: 'LinkedIn' },
    { icon: faDiscord, url: 'https://discord.com/invite/keyyHq6TS4', name: 'Discord' }
  ];
  return (
    <div className="social-icons-container">
      {socialLinks.map((social, index) => (
        <a
          key={index}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
          aria-label={social.name}
        >
          <FontAwesomeIcon icon={social.icon} />
        </a>
      ))}
    </div>
  );
};
export default SocialMediaIcons;