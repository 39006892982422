import React from 'react'
import { Link } from "react-router-dom";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Footer from '../components/Footer';



export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const UnknownPage = () => {
  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
          <h2 className='headerTextCompany' >THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>


      <div className="app-container" >
        <div style={{ textAlign: 'center' }}>
          <h2 style={{
            fontSize: "30px", fontFamily: "Poppins, sans-serif",

          }}>ERROR</h2>




        </div>
        <p style={{ fontSize: '20px' }}>We tried to find the content you are looking for but the search resulted in an ERROR! "404" Please use a navigation menu to continue or click <a href='/' style={{color: 'red'}}>here</a> to return home.
        


</p>
<br></br>
<h2 style={{ fontSize: '25px', color: "black", fontWeight: 'bold',             fontFamily: "Poppins, sans-serif",
 }}>QUICK LINKS</h2>
   <ol style={{ fontSize: '20px' }}>
            <li ><a href="/" style={{ color: 'red',  fontWeight: 'bold', }}>HOME</a></li>
            <li><a href="/#about-us" style={{ color: 'red',  fontWeight: 'bold', }}>ABOUT US</a></li>
            <li><a href="/#portfolio" style={{ color: 'red',  fontWeight: 'bold', }}>PORTFOLIO</a></li>
            <li><a href="/#contact-us" style={{ color: 'red',  fontWeight: 'bold', }}>CONTACT US</a></li>


          </ol>

    

      </div>
      
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div style={{backgroundColor: 'black', marginTop: '100px' }}>
      <div className="footer-content" style={{ backgroundColor: 'black' }}>
         <Footer/>
        </div>
</div>
    </div>
  )
}

export default UnknownPage
