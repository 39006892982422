import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "../../script/checkUnderline";
import '../../PreviewPage.scss'; // Import your SCSS file for styling
import "./FullPortfolio.css"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Footer from '../../components/Footer';
 
const PortfolioList = [
  {
    images: "26",
    category: "ASSET",
    title: "Street Sign Pack",
    serve: "/portfolio/street-sign-pack",
    platform: "",
  },
  {
    images: "24",
    category: "SOFTWARE",
    title: "styleSweeper",
    serve: "/portfolio/stylesweeper",
    platform: "",
  },
  {
    images: "25",
    category: "SOFTWARE",
    title: "processLogger",
    serve: "/portfolio/processlogger",
    platform: "",
  },
  {
    images: "23",
    category: "SOFTWARE",
    title: "devKick",
    serve: "/portfolio/devkick",
    platform: "",
  },
  {
    images: "21",
    category: "ASSET",
    title: "RISE Carnival",
    serve: "/portfolio/rise-carnival",
    platform: "",
  },
  {
    images: "20",
    category: "ASSET",
    title: "Orbiteer",
    serve: "/portfolio/orbiteer",
    platform: "",
  },
  {
    images: "19",
    category: "ASSET",
    title: "Mr Bird",
    serve: "/portfolio/mr-bird",
    platform: "",
  },
  {
    images: "18",
    category: "ASSET",
    title: "Spooky Sword",
    serve: "/portfolio/spooky-sword",
    platform: "",
  },
  {
    images: "17",
    category: "ASSET",
    title: "Banjo",
    serve: "/portfolio/banjo",
    platform: "",
  },
  {
    images: "16",
    category: "ASSET",
    title: "RISE Castaways",
    serve: "/portfolio/rise-castaways",
    platform: "",
  },
  {
    images: "15",
    category: "ASSET",
    title: "RISE Kame House",
    serve: "/portfolio/rise-kamehouse",
    platform: "",
  },
  {
    images: "2",
    category: "ASSET",
    title: "RISE Arcade",
    serve: "/portfolio/rise-arcade",
    platform: "",
  },
  {
    images: "10",
    category: "BRAND",
    title: "redlightcam",
    serve: "/portfolio/redlightcam",
    platform: "",
  },
  {
    images: "3",
    category: "SOFTWARE",
    title: "Baaridge",
    serve: "/portfolio/baaridge",
    platform: "",
  },
  {
    images: "4",
    category: "SOFTWARE",
    title: "BL1NK",
    serve: "/portfolio/bl1nk",
    platform: "",
  },
  {
    images: "1",
    category: "SOFTWARE",
    title: "Keep On Trucking",
    serve: "/portfolio/keep-on-trucking",
    platform: "",
  },
  {
    images: "8",
    category: "SOFTWARE",
    title: "Stack 8",
    serve: "/portfolio/stack-8",
    platform: "",
  },
];
export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title;
    window.document.title = title;
    return () => {
      window.document.title = prevTitle;
    };
  });
}
const FullPortfolio = () => {
  const [query, setSearchTerm] = useState('');
  const [filteredCategory, setFilteredCategory] = useState(''); // To filter by category
  // Add event listener for scroll
  useEffect(() => {
    const handleScroll = () => {
      // Check if we are at the bottom of the page
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        // setVisibleCount(prevCount => prevCount + 5); // Load 5 more cards
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  useTitle("Portfolio - RISE");
  return (
    <div className="creative-portfolio" >
      
      <div className="flex-box-header header" id="detail">
        
        <div className="headerText">
          <a href="/">
            <h2 className='headerTextCompany'>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p className='portfolioSelected' id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
          
        </div>
        
      </div>
      
      <div className='portfolioContainer'>


        <h2 id="contactUsHeader" className='portfolioPageHeader'>
          PORTFOLIO
        </h2>

        <p className='portfolioDescription'>
          THE RISE COLLECTION portfolio includes {""}<span className='redBrandingText'>BRANDS</span>, {""}<span className='redBrandingText'>ASSETS</span> and custom {""}<span className='redBrandingText'>SOFTWARE</span> solutions.
        </p>
        <br />
        {/* Filter Dropdown */}

      </div>
      <div className='filterOptionsContainer'>
        {/* Search Bar */}
        <input
          type="text"
          placeholder="SEARCH..."
          onChange={(e) => setSearchTerm(e.target.value)}
          className="searchBar"
        />
        {/* Category Filter */}
        <div className='categoryFilterContainer'>
          <select
            onChange={(e) => setFilteredCategory(e.target.value)}
            value={filteredCategory}
            className="categoryFilter"
          >
            <option value="">FILTER BY CATEGORY</option>
            <option value="ASSET">ASSET</option>
            <option value="BRAND">BRAND</option>
            <option value="SOFTWARE">SOFTWARE</option>
          </select>
        </div>
        

      </div>

      <div className='gridContainer' style={{ paddingTop: '50px', paddingBottom: '150px' }}>

        {PortfolioList.filter((value) => {
          if (query === '') {
            return true;
          } else if (value.title.toLowerCase().includes(query.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        }).filter((value) => {
          // Apply category filter
          if (filteredCategory === '') return true;
          return value.category === filteredCategory;
        }).slice(0).map((value, i) => (
          <div key={i}>
            <a href={value.serve} style={{ textDecoration: 'none' }}>
              <Card style={{ backgroundColor: 'black' }} sx={{ minWidth: 345, border: '1px solid', borderColor: '#282828' }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={`/assets/images/portfolio/portfolio-${value.images}.jpg`}
                    alt="Portfolio Images"
                  />
                  <CardContent style={{ backgroundColor: 'black' }}>
                    <Typography variant="body2" color="red">
                      {value.category} {value.platform}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      <a style={{ color: 'white', textDecoration: "none" }} href={value.serve}>{value.title}</a>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </a>
          </div>
        ))}
      </div>
      <div style={{ backgroundColor: 'black' }}>
        <div className="footer-content" style={{ backgroundColor: 'black' }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default FullPortfolio;