import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageScrollTop from "./PageScrollTop";
import AppTest from "./AppTest";
import PrivacyPolicy from "./views/Privacy/PrivacyPolicy";
import ContactUsPage from "./script/ContactUsPage";
import AboutUsPage from "./AboutUsPage";
import BL1NKDetailPage from "./BL1NKDetailPage";
import BaaridgeDetailPage from "./views/Baaridge/BaaridgeDetailPage";
import KeepOnTruckingDetailPage from "./script/KeepOnTruckingDetailPage";
import Stack8DetailPage from "./views/Stack8/Stack8DetailPage";
import RedLightCamDetailPage from "./views/redlightcam/redlightcam"; // Ensure the path is correct
import UnknownPage from "./script/UnknownPage";
import HelloWorld from "./script/HelloWorld";
import RiseKameHouse from "./script/risekamehouse";
import RiseCastaways from "./script/RiseCastaways";
import Banjo from "./script/banjo";
import SpookySword from "./script/spooky-sword";
import MrBird from "./views/MrBird/mr-bird";
import Orbiteer from "./views/Orbiteer/orbiteer";
import FullPortfolio from "./views/Portfolio/FullPortfolio";
import RiseCarnival from "./script/RiseCarnival";
import TermsOfUse from "./views/Privacy/TermsOfUse";
import DevKick from "./script/devkick";
import LogOff from "./script/logggggoff";
import StyleSweeper from "./script/stylesweeper";
import StreetSignPack from "./views/Orbiteer/streetsign";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter basename="/">
    <PageScrollTop>
      <Routes>
        <Route path="/" element={<AppTest />} />
        <Route path="/portfolio" element={<FullPortfolio />} />

        <Route path="/portfolio/devkick" element={<DevKick />} />
        <Route path="/portfolio/processlogger" element={<LogOff/>} />
        <Route path="/portfolio/stylesweeper" element={<StyleSweeper/>} />

        <Route path="/portfolio/street-sign-pack" element={<StreetSignPack />} />
        <Route path="/portfolio/orbiteer" element={<Orbiteer />} />
        <Route path="/portfolio/mr-bird" element={<MrBird />} />
        <Route path="/portfolio/spooky-sword" element={<SpookySword />} />
        <Route path="/portfolio/banjo" element={<Banjo />} />

        <Route path="/portfolio/rise-kamehouse" element={<RiseKameHouse />} />
        <Route path="/portfolio/rise-arcade" element={<HelloWorld />} />
        <Route path="/portfolio/rise-castaways" element={<RiseCastaways />} />
        <Route path="/portfolio/rise-carnival" element= {<RiseCarnival />} />

        <Route
          path="/portfolio/redlightcam"
          element={<RedLightCamDetailPage />}
        />
        <Route path="/portfolio/stack-8" element={<Stack8DetailPage />} />
        <Route
          path="/portfolio/keep-on-trucking"
          element={<KeepOnTruckingDetailPage />}
        />
        <Route path="/portfolio/baaridge" element={<BaaridgeDetailPage />} />
        <Route path="/portfolio/bl1nk" element={<BL1NKDetailPage />} />

        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

     
        <Route path="#about-us" element={<AboutUsPage />} />
        <Route path="#contact-us" element={<ContactUsPage />} />
        <Route path="*" element={<UnknownPage />} />
      </Routes>
    </PageScrollTop>
  </BrowserRouter>
);
