import React from "react";
import { Link } from "react-router-dom";
import {
    homeClick,
    underlineMenuAbout,
    underlineMenuContact,
    underlineMenuPortfolio,
} from "../script/checkUnderline";


class Header extends React.Component {
    render() {
      return (
        <div className="flex-box-header header" id="detail">
        <div className="headerText">
            <a href="/">
                <h2 className='headerTextCompany' >THE RISE COLLECTION</h2>
            </a>
        </div>
        <div className="menuItems">
            <Link to="/" onClick={homeClick}>
                <p id="homeMenuText">HOME</p>
            </Link>
            <Link to="/#about-us" onClick={underlineMenuAbout}>
                <p id="aboutUsMenuText">ABOUT US</p>
            </Link>
            <Link  onClick={underlineMenuPortfolio}>
                <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
            </Link>
            <Link to="/#contact-us" onClick={underlineMenuContact}>
                <p id="mediaMenuText">CONTACT US</p>
            </Link>
        </div>
    </div>
      );
    }
  }
  

  export default Header