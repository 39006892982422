import React from 'react'
import '../../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

const iconImage = require("../../script/test-img/portfolio-8.jpeg");
const screenShot1 = require("../../script/test-img/stack-1.png");
const screenShot2 = require("../../script/test-img/stack-2.png");
const screenShot3 = require("../../script/test-img/stack-3.png");

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const Stack8DetailPage = () => {
    useTitle("Stack 8 - RISE")
    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
            <Header />
            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                        <h2 style={{ fontSize: "18px" }}>STACK 8</h2>
                        <p ><a style={{ fontSize: '15px', color: 'red', fontWeight: 'bold' }} href="/#about-us">THE RISE COLLECTION</a></p>
                        <div className='tag-container'>
                            <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', width: '100px', paddingTop: '0.98px' }}>SOFTWARE</div>
                            <div className='left-tag' style={{ background: 'blue', width: '120px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>MOBILE GAME</div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the Mobile Game</h2>
                    <p style={{ fontSize: '18px' }}>
                        Stack 8 is Endless! Align your pixels and tap to stack. Be precise... misalignment will cost you a pixel! How high can you stack?
                    </p>
                    <br></br>
                </div>
                <h2>Media</h2>
                <div className="screenshot-container-web">
                    <img src={screenShot1} alt='STACK8_SCREENSHOT_1' />
                    <img src={screenShot2} alt='STACK8_SCREENSHOT_2' />
                    <img src={screenShot3} alt='STACK8_SCREENSHOT_3' />
                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: August 12, 2016</li>
                        <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
                            style={{ color: "red", fontWeight: 'bold' }}>THE RISE COLLECTION</a></li>                    </ul>
                </div>
                <br></br>
                <h2>Download</h2>
                <div className="game-description">
                <p>Find this software on the following platforms:</p>
                    <ul style={{ fontSize: '18px' }}>
                        <li><a href='https://apps.apple.com/mm/app/stack-8/id1093455092' style={{ color: 'red', fontWeight: 'bold' }}>APPLE APP STORE</a></li>
                    </ul>
                </div>
            </div>
            <br></br>
            <div style={{ backgroundColor: 'black', }}>

                <div className="footer-content" style={{ backgroundColor: 'black' }}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Stack8DetailPage
