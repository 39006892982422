

export function underlineMenuPortfolio() {
  // Determine the correct portfolio link based on current URL
  const currentUrl = window.location.pathname;
  const portfolioLink = currentUrl.includes('/portfolio')
    ?  window.location.replace('/portfolio')
    : '/#portfolio';
  // Menu underline functionality
  if (document.getElementById("gamesMenuText")) {
    document.getElementById("aboutUsMenuText").style.borderBottom = "none";
    document.getElementById("mediaMenuText").style.borderBottom = "none";
    if (
      document.getElementById("gamesMenuText").style.borderBottom ===
      "solid 2px"
    ) {
      console.log("border exists");
    } else {
      document.getElementById("gamesMenuText").style.borderBottom = "solid 2px";
      document.getElementById("gamesMenuText").style.borderColor = "red";
    }
  }
  return portfolioLink; // Optional: returning the link for potential further use
}

export function underlineMenuAbout() {
  if (document.getElementById("aboutUsMenuText")) {
    document.getElementById("aboutUsMenuText").style.borderBottom = "none";
    document.getElementById("mediaMenuText").style.borderBottom = "none";
    document.getElementById("homeMenuText").style.borderBottom = "none";

    document.getElementById("gamesMenuText").style.borderBottom = "none";

    document.getElementById("aboutUsMenuText").style.borderBottom = "solid 2px";
    document.getElementById("aboutUsMenuText").style.borderColor = "red";
  }
}

export function underlineMenuContact() {
  if (document.getElementById("mediaMenuText")) {
    document.getElementById("aboutUsMenuText").style.borderBottom = "none";
    document.getElementById("gamesMenuText").style.borderBottom = "none";

    document.getElementById("mediaMenuText").style.borderBottom = "solid 2px";
    document.getElementById("mediaMenuText").style.borderColor = "red";
  }
}

export function redUnderline() {
  if (window.location.href === "/#about-us") {
    document.getElementById("aboutUsMenuText").style.borderBottom = "solid 2px";
    document.getElementById("aboutUsMenuText").style.borderColor = "red";
  }
}

export function ensureUnderline() {
  if (window.location.href === "") {
    console.log(`href: ${window.location.href}`);
    document.getElementById("homeMenuText").style.borderBottom = "solid 2px";
    document.getElementById("homeMenuText").style.borderColor = "red";
  }
}

export function onContactUsPage() {
  if (window.location === "/contact-us") {
    document.getElementById("contactMenuText").style.borderBottom = "solid 2px";
    document.getElementById("contactMenuText").style.borderColor = "red";
  }
}

export function portfolioClickFromAboutUs() {
  window.location.assign("");
}

export function homeClick() {
  
  document.getElementById("aboutUsMenuText").style.borderBottom = "none";
  document.getElementById("gamesMenuText").style.borderBottom = "none";
  document.getElementById("contactMenuText").style.borderBottom = "none";
  document.getElementById("homeMenuText").style.borderBottom = "none";

 

//  var homeVideo = document.getElementById("video")
//  homeVideo.src = homeVideo.src
//  homeVideo.load()

}

export function portfolioClickFromContact() {
  window.location.assign("#portfolio");
}

export function loadLinkedin() {
  window.location.assign("www.google.com");
}

export function backHome() {
  if (document.getElementById("backHome")) {
    document.getElementById("backHome").style.borderBottom = "solid 2px";
    document.getElementById("backHome").style.borderColor = "red";
  }
}



export function mediaMenu() {
  if (document.getElementById("mediaMenuText")) {
    document.getElementById("gamesMenuText").style.borderBottom = "none";
    document.getElementById("softwareMenuText").style.borderBottom = "none";

    document.getElementById("mediaMenuText").style.borderBottom = "solid 2px";
    document.getElementById("mediaMenuText").style.borderColor = "red";
  }
}

export function softwareMenu() {
  if (document.getElementById("softwareMenuText")) {
    document.getElementById("gamesMenuText").style.borderBottom = "none";
    document.getElementById("mediaMenuText").style.borderBottom = "none";

    document.getElementById("softwareMenuText").style.borderBottom =
      "solid 2px";
    document.getElementById("softwareMenuText").style.borderColor = "red";
  }
}
