import React from 'react'
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const iconImage = require("./test-img/portfolio-15.jpg");
const screenShot1 = require("./test-img/kamehouse.png");


export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const RiseKameHouse = () => {
    useTitle("RISE Kame House - RISE")
    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
            <Header />
            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                        <h2 style={{ fontSize: "18px" }}>RISE Kame House</h2>
                        <p ><a style={{ fontSize: '15px', color: 'red', fontWeight: 'bold' }} href="/#about-us">THE RISE COLLECTION</a></p>
                        <div className='tag-container'>
                            <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px', width: '75px' }}>ASSET</div>
                            <div className='left-tag' style={{ background: '#3AE9C9', width: '130px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>NIFTY ISLAND</div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the Asset</h2>
                    <p style={{ fontSize: '18px' }}>
                        RISE Kame House is a recreation of a scene from the "Dragon Ball Z" TV Show on the Nifty Island Meta-Verse. Nifty Island is centered around user-generated content (UGC), allowing players to terraform landscapes, construct buildings, and decorate with foliage.
                    </p>
                    <p style={{ fontSize: '18px' }}>
                        Players can build and decorate their own islands, complete quests, and play games with friends. Players can also use their non-fungible tokens (NFTs) as avatars in the game.
                    </p>
                    <p style={{ fontSize: '18px' }}>
                        Nifty Island is a product of a Texas-based company that offers a platform for communities to engage in immersive gaming experiences.
                    </p>
                    <br></br>
                </div>
                <h2>Media</h2>
                <div className="screenshot-container-sideways">
                    <img src={screenShot1} alt='HELLO_WORLD_SCREENSHOT_1' />
                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: December 27, 2024</li>
                        <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
                            style={{ color: "red", fontWeight: 'bold' }}>THE RISE COLLECTION</a></li>
                        <li>Category: UGC</li>
                    </ul>
                </div>
                <br></br>
                <h2>Explore</h2>
                <div className="game-description">
                    <p>Find this asset on the following platforms:</p>
                    <ul style={{ fontSize: '18px' }}>
                        <li><a href='https://niftyis.land/Elboral/0' style={{ color: 'red', fontWeight: 'bold' }}>NIFTY ISLAND</a></li>
                    </ul>
                </div>
            </div>
            <br></br>
            <div style={{ backgroundColor: 'black', }}>
                <div className="footer-content" style={{ backgroundColor: 'black' }}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default RiseKameHouse
