import React from 'react'
import './PreviewPage.scss'; // Import your SCSS file for styling
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
const iconImage = require("../src/script/test-img/portfolio-4.jpeg");
const screenShot1 = require("../src/script/test-img/bl1nk-1.png");
const screenShot2 = require("../src/script/test-img/bl1nk-2.png");
const screenShot3 = require("../src/script/test-img/bl1nk-3.png");

const TITLE = 'BL1NK - THE RISE COLLECTION';

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const BL1NKDetailPage = () => {
  useTitle("BL1NK - RISE")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Header />
      <div className="app-container">
        <div className="game-info">
          <img src={iconImage} alt="Game Icon" className="game-icon" />
          <div className="game-details">
            <h2 style={{ fontSize: "18px" }}>BL1NK</h2>
            <p ><a style={{ fontSize: '15px', color: 'red', fontWeight: 'bold' }} href="/#about-us">THE RISE COLLECTION</a></p>
            <div className='tag-container'>
              <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', width: '100px', paddingTop: '0.98px' }}>SOFTWARE</div>
              <div className='left-tag' style={{ background: 'blue', width: '120px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>MOBILE GAME</div>
            </div>
          </div>
        </div>
        <br></br>
        <div style={{ marginBottom: '20px' }}>
          <h2>About the Mobile Game</h2>
          <p style={{ fontSize: '18px' }}>
            Avoid Obstacles by Tilting! BL1NK uses your device's accelerometer to navigate a falling cube. How long can you keep falling before you BL1NK? Use your device's sensors to guide the cube safely down the map!
          </p>
          <br></br>

        </div>
        <h2>Media</h2>

        <div className="screenshot-container-sideways">

          <img src={screenShot1} alt='BL1NK_SCREENSHOT_1' />
          <img src={screenShot3} alt='BL1NK_SCREENSHOT_3' />

          <img src={screenShot2} alt='BL1NK_SCREENSHOT_2' />

        </div>
        <br></br>
        <div style={{ marginBottom: '20px' }}>
          <h2>Additional Information</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>Release Date: July 15, 2017</li>
            <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
              style={{ color: "red", fontWeight: 'bold'}}>THE RISE COLLECTION</a></li>
          </ul>
        </div>
        <br></br>
        <h2>Download</h2>
        <div className="game-description">
        <p>Find this software on the following platforms:</p>
          <ul style={{ fontSize: '18px' }}>
            <li><a href='https://apps.apple.com/mm/app/bl1nk/id1025073240' style={{ color: 'red', fontWeight: 'bold' }}>APPLE APP STORE</a></li>
          </ul>

          {/* <a href='https://play.google.com/store/apps/details?id=com.therisecollection.blink&hl=en_US&gl=US'><img src={androidImage} style={{ width: '175px', marginLeft: '-10px' }} alt='BL1NK_DOWNLOAD_ANDROID' /></a> */}

        </div>


      </div>
      <br></br>
      <div style={{ backgroundColor: 'black', }}>
        <div className="footer-content" style={{ backgroundColor: 'black' }}>
          <Footer />
        </div>
      </div>
    </div>
  )


}



export default BL1NKDetailPage
