import React from "react";
import SocialMediaIcons from "./SocialMediaIcons";
const footerLogo = require("../script/test-img/logo.png");



class Footer extends React.Component {
    render() {
      return (
        <footer className="footer">
        <div className="footer-content" style={{ backgroundColor: 'black' }}>

            
            <div className="footer-content" style={{ paddingBottom: "5px" }}>

              <div className="footer-social">

                <img alt="rise_logo" src={footerLogo} style={{ width: "75px" }} />
                <SocialMediaIcons/>

              <div className='footerLinks'>
                <div className='footerLinkA'><a href='/privacy-policy'>PRIVACY POLICY</a></div>
                <div className='footerLinkA'><a href="https://the-rise-collection.gitbook.io/the-rise-collection">DOCUMENTATION</a></div>
                <div className='footerLinkA'><a href='/terms-of-use'>TERMS OF USE</a></div>
                <div className='footerLinkA'><a href='/#contact-us'>CONTACT US</a></div>

              </div>


                <br />
                <br />
                <div style={{ marginTop: "-30px" }}></div>
              </div>
            </div>
        </div>
        </footer>
      );
    }
  }
  

  export default Footer